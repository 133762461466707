import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userAuth: false,
    createEmail: null,
    firstLoad: true,
    eventId: null,
    meeting: null,
  },
  getters: {
    getUserAuth: (state) => {
      return state.userAuth;
    },
    getCreateEmail: (state) => {
      return state.createEmail;
    },
    getFirstLoad: (state) => {
      return state.firstLoad;
    },
    getEventId: (state) => {
      return state.eventId;
    },
    getMeeting: (state) => {
      return state.meeting;
    },
  },
  mutations: {
    userAuthSave(state, value) {
      state.userAuth = value;
    },
    userCreateEmail(state, value) {
      state.createEmail = value;
    },
    firstLoadSave(state, value) {
      state.firstLoad = value;
    },
    eventIdSave(state, value) {
      state.eventId = value;
    },
    meetingSave(state, value) {
      state.meeting = value;
    },
  },
  actions: {},
  modules: {},
});
