/* eslint-disable */
import { relativeTimeThreshold } from "moment";
import Setting from "../../setting.js";
import ExcelExport from "@/components/ExcelExport.vue";

export default {
  name: "HomeView",
  components: {
    ExcelExport,
  },
  data: () => ({
    hasAccount: false,
    hasNotAccount: false,
    showAlert: false,
    alertType: null,
    alertMessage: "",
    valid: false,
    validAccount: false,
    loading: false,
    tokenRequest: null,
    meeting: null,
    agree: false,
    name: "",
    email: "",
    company: "",
    phone: "",
    token: null,
    status: null,
    statuses: [
      {
        text: "On-site",
      },
      {
        text: "Remote",
      },
      {
        text: "I will not join",
      },
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 30 || "Name must be less than 30 characters",
    ],
    companyRules: [
      (v) => !!v || "Company is required",
      (v) => v.length <= 30 || "Company must be less than 30 characters",
    ],
    phoneRules: [
      (v) => v.length <= 20 || "Phone must be less than 20 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
      (v) =>
        /^[a-zA-Z0-9._-]+@(?!gmail\.com)(?!yahoo\.com)(?!Hotmail\.com)(?!Outlook\.com)(?!Aol\.com)(?!Mail\.com)(?!Yandex\.ru)(?!Icloud\.com)(?!Msn\.com)(?!Live\.com)(?!Rediffmail\.com)(?!163\.com)(?!QQ\.com)(?!Gmx\.com)(?!Zoho\.com)(?!Mail\.ru)(?!Protonmail\.com)(?!Outlook\.com\.br)(?!Naver\.com)(?!Daum\.net)(?!seznam\.cz)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(
          v
        ) || "Please, register with your work e-mail address",
    ],
    fields: {
      Email: "Email",
      Web: "Web",
      Brand: "Brand",
      Name: "Name",
      Telephone: "Telephone",
      Linkedln: "Linkedln",
      Address: "Address",
      BrandContactWeb: "BrandContactWeb",
      BrandContactName: "BrandContactName",
      BrandContactPhone: "BrandContactPhone",
      BrandContactEmail: "BrandContactEmail",
      BrandContactLinkedln: "BrandContactLinkedln",
      BrandContactAddress: "BrandContactAddress",
    },
    brands: [
      {
        id: "1",
        name: "AT&T",
        contact: {},
      },
      {
        id: "2",
        name: "CommScope",
        contact: {},
      },
      {
        id: "3",
        name: "Viavi",
        contact: {
          name: "",
          telephone: "",
          Web: " https://www.viavisolutions.com/en-us",
          Address: "",
          Linkedln: "https://www.viavisolutions.com/en-us",
          Twitter: "",
        },
      },
      {
        id: "4",
        name: "NEC",
        contact: {
          Web: "https://www.nec.com/",
        },
      },
      {
        id: "5",
        name: "Mavenir",
        contact: {
          name: "Mavenir Systems",
          Web: "https://mavenir.com/",
          Address:
            "1700 International Pkwy, Richardson, TX 75081, United States",
        },
      },
      {
        id: "6",
        name: "Radisys",
        contact: {},
      },
      {
        id: "7",
        name: "Ciena",
        contact: {},
      },
      {
        id: "8",
        name: "Wind River",
        contact: {},
      },
      {
        id: "9",
        name: "DISH Network",
        contact: {},
      },
      {
        id: "10",
        name: "China Mobile",
        contact: {},
      },
      {
        id: "11",
        name: "Lenovo",
        contact: {
          Email: "wangzy77@lenovo.com",
          Web: "https:www.lenovo.com.cn/",
          Linkedln: "https://www.linkedin.com/company/lenovo/",
        },
      },
      {
        id: "12",
        name: "Altran",
        contact: {},
      },
      {
        id: "13",
        name: "Baicells",
        contact: {},
      },
      {
        id: "14",
        name: "QCT",
        contact: {},
      },
      {
        id: "15",
        name: "Intel",
        contact: {},
      },
      {
        id: "16",
        name: "ArrayComm",
        contact: {
          name: "",
          Email: "info@arraycomm.com",
          Web: "http://www.arraycomm.com/",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "17",
        name: "CIG",
        contact: {},
      },
      {
        id: "18",
        name: "Altran",
        contact: {},
      },
      {
        id: "20",
        name: "IPLOOK",
        contact: {
          name: "",
          telephone: "",
          Email: "sales@iplook.com",
          Web: "www.iplook.com",
          Address:
            "Suite 1101, On Hong Commercial Building, 145 Hennessy Road, Wanchai Hong Kong",
          Linkedln: "https://www.linkedin.com/company/iplook",
          Twitter: "",
          YouTube: "https://www.youtube.com/channel/UC-N2VLr0_Z1SRXZO_x66yGA",
        },
      },
      {
        id: "21",
        name: "CertusNet",
        contact: {},
      },
      {
        id: "22",
        name: "NTS",
        contact: {},
      },
      {
        id: "23",
        name: "VMware",
        contact: {
          www: "https://www.vmware.com/",
        },
      },
      {
        id: "24",
        name: "Netsia",
        contact: {},
      },
      {
        id: "25",
        name: "NTT DOCOMO",
        contact: {},
      },
      {
        id: "26",
        name: "BravoCom",
        contact: {},
      },
      {
        id: "27",
        name: "Nokia",
        contact: {},
      },
      {
        id: "28",
        name: "Sterlite",
        contact: {},
      },
      {
        id: "29",
        name: "O-RAN ALLIANCE",
        contact: {
          name: "O-RAN ALLIANCE e.V.",
          telephone: "+420 603 400 524",
          Email: "zbynek.dalecky@o-ran.org",
          Web: "www.o-ran.org",
        },
      },
      {
        id: "30",
        name: "Sageran",
        contact: {
          name: "Guangzhou Sageran Technology Co., Ltd.",
          telephone: "+86-020-83706770",
          Email: "business@sageran.com",
          Web: "http://www.sageran.com/en/",
          Linkedln: "https://www.linkedin.com/company/sageran",
        },
      },
      {
        id: "31",
        name: "Keysight",
        contact: {
          Web: "https://www.keysight.com/us/en/home.html",
        },
      },
      {
        id: "32",
        name: "Benetel",
        contact: {
          name: "Benetel Ltd",
          telephone: "+353 1 410 0890",
          Email: "sales@benetel.com",
          Web: "http://www.benetel.com",
          Address:
            "Benetel Limited, The Guinness Enterprise Centre (GEC), Taylor's Ln, The Liberties, Dublin 8, Ireland",
          Linkedln: "https://ie.linkedin.com/company/benetel-ltd",
        },
      },
      {
        id: "33",
        name: "Parallel Wireless",
        contact: {
          name: "Parallel Wireless",
          Web: "http://www.parallelwireless.com",
          Address: "",
          Email: "info@parallelwireless.com",
          Linkedln: " https://www.linkedin.com/company/parallel-wireless-inc-/",
          Twitter: " https://twitter.com/parallel_tw/",
        },
      },
      {
        id: "34",
        name: "Ericsson",
        contact: {},
      },
      {
        id: "35",
        name: "China Unicom",
        contact: {},
      },
      {
        id: "36",
        name: "Comba Telecom",
        contact: {
          Web: "https://www.comba-telecom.com/en",
          Linkedln: "https://www.linkedin.com/company/comba-telecom/",
          YouTube: " https://www.youtube.com/channel/UCqOIMCMBwU06NWlY33qDr7Q",
        },
      },
      {
        id: "37",
        name: "Nvidia",
        contact: {
          telephone: " (+1)978-761-2901",
          Email: "lkundu@nvidia.com",
          Web: "https://www.nvidia.com/en-us/industries/telecommunications/",
          Address: "2788 San Tomas Expy, Santa Clara, CA 95051, USA",
          Linkedln: " https://in.linkedin.com/company/nvidia",
          Twitter: " https://twitter.com/nvidia",
        },
      },
      {
        id: "38",
        name: "Chengdu NTS",
        contact: {
          Email: "violeta.geng@nts-intl.com",
          Web: "http://www.nts-intl.com",
          Linkedln: "www.linkedin.com/company/15215724/admin/",
        },
      },
      {
        id: "39",
        name: "Airspan Networks",
        contact: {
          name: "Abel Mayal",
          telephone: "+1 561-893-8670",
          fax: "+1 561-893-8671",
          Email: "amayal@Airspan.com",
          Web: "www.airspan.com",
          Address: "777 Yamato Road, Boca Raton, FL 33431 USA",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "40",
        name: "Juniper Networks",
        contact: {
          fax: "",
          Web: "www.juniper.net",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "41",
        name: "Xilinx",
        contact: {
          telephone: "+1 408 879 3649",
          fax: "",
          Email: "sassan.ahmadi@xilinx.com",
          Web: "https://www.xilinx.com/applications/wired-wireless/telco.html",
          Address:
            "Xilinx Inc.,2100 All Programmable Dr.,San Jose, CA 95124 USA",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "42",
        name: "Calnex Solutions",
        contact: {
          telephone: "+44 1506 671416",
          fax: "",
          Email: "info@calnexsol.com",
          Web: "https://calnexsol.com/",
          Web2: "https://calnexsol.cn",
          Address: "",
          Linkedln: "https://www.linkedin.com/company/calnex-solutions-ltd./",
          Twitter: "",
        },
      },
      {
        id: "43",
        name: "Fujitsu",
        contact: {
          telephone: "",
          fax: "",
          Email: "",
          Web: "https://www.fujitsu.com/global/",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "44",
        name: "Samsung",
        contact: {
          telephone: "",
          fax: "",
          Email: "junhyuk.song@samsung.com",
          name: "Jun Hyuk Song",
          Web: "",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "45",
        name: "highstreet technologies",
        contact: {
          telephone: "",
          fax: "",
          Email: "alexandru.stancu@highstreet-technologies.com",
          Web: "",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "46",
        name: "Rutgers University",
        contact: {
          telephone: "",
          fax: "",
          Email: "",
          Web: "",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "47",
        name: "ITRI",
        contact: {},
      },
      {
        id: "48",
        name: "Viettel",
        contact: {
          fax: "",
          Web: "https://viettelhightech.com.vn/en",
          Address: "380 Lac Long Quan St,. Nhat Tan, Tay Ho District, Hanoi",
          Linkedln: "https://www.linkedin.com/company/viettel-high-tech/",
          Twitter: "",
        },
      },
      {
        id: "49",
        name: "Altiostar",
        contact: {
          telephone: "",
          fax: "",
          Email: "info@altiostar.com",
          Web: "https://www.altiostar.com/",
          Address: "200 Ames Pond Drive, Tewksbury, MA 01876",
          Linkedln: "https://www.linkedin.com/company/alitostar/",
          Twitter: "https://twitter.com/altiostar?lang=en",
        },
      },
      {
        id: "50",
        name: "Bharti Airtel",
        contact: {
          telephone: "+91-9535255353",
          name: "Santram Vashist",
          fax: "",
          Email: "santram.vashist@airtel.com",
          Web: "https://www.airtel.in/",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "51",
        name: "Capgemini",
        contact: {
          name: "Subhash Chopra",
          telephone: "",
          Email:
            "Subhash.chopra@capgemini.com https://www.linkedin.com/in/subhash-chopra-7599455/",
          Web: "https://capgemini-engineering.com/",
          Linkedln: "https://www.linkedin.com/company/altran/",
        },
      },
      {
        id: "52",
        name: "IS-Wireless",
        contact: {
          telephone: "",
          name: "",
          fax: "",
          Email: "info@is-wireless.com",
          Web: "https://www.is-wireless.com/",
          Address: "ul. Pulawska 45b, 05-500 Piaseczno / near Warsaw, POLAND",
          Linkedln: "https://www.linkedin.com/company/is-wireless/",
          Twitter: "",
        },
      },
      {
        id: "53",
        name: "PEGATRON",
        contact: {},
      },
      {
        id: "54",
        name: "CMCC",
        contact: {
          telephone: "",
          fax: "",
          name: "",
          Email: "huangjinri@chinamobile.com",
          Web: "www.chinamobile.com",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "55",
        name: "Siemens EDA",
        contact: {
          telephone: "",
          fax: "",
          name: "",
          Email: "sarokal_sales@mentor.com",
          Web: "https://eda.sw.siemens.com/en-US/ic/veloce",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "56",
        name: "H3C",
        contact: {
          telephone: "",
          fax: "",
          name: "",
          Email: "huang.danling@h3c.com",
          Web: "www.h3c.com",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "57",
        name: "Analog Devices",
        contact: {
          telephone: "",
          fax: "",
          name: "",
          Email: "",
          Web: "https://analog.com/Radioverse",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "58",
        name: "Rohde & Schwarz",
        contact: {
          telephone: "",
          fax: "",
          name: "",
          Email: "",
          Web: "https://www.rohde-schwarz.com/wireless/o-ran",
          Address: "",
          Linkedln: "https://www.linkedin.com/showcase/rohde-schwarz-wireless/",
          Twitter: "",
        },
      },
      {
        id: "59",
        name: "HCL",
        contact: {
          telephone: "",
          fax: "",
          name: "",
          Email: "rsriraman@hcl.com",
          Web: "",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "60",
        name: "TIM",
        contact: {
          telephone: "",
          fax: "",
          name: "",
          Email: "Marco.caretti@telecomitalia.it",
          Web: "http://www.gruppotim.it",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "61",
        name: "Arm",
        contact: {
          telephone: "",
          fax: "",
          name: "Mo Jabbari",
          Email: "mo.jabbari@arm.com",
          Web: "https://www.arm.com",
          Address: "",
          Linkedln: "https://www.linkedin.com/company/arm",
          Twitter: "",
        },
      },
      {
        id: "62",
        name: "Astri",
        contact: {
          telephone: "",
          fax: "",
          name: "Eric Kong-Chau Tsang",
          Email: "erictsang@astri.org",
          Web: "https://www.astri.org",
          Address: "",
          Linkedln: "https://www.linkedin.com/company/astri/",
          Twitter: "",
        },
      },
      {
        id: "63",
        name: "NXP",
        contact: {
          telephone: "",
          fax: "",
          name: "Wim Rouwet",
          Email: "wim.rouwet@nxp.com",
          Web: "https://www.nxp.com",
          Address: "",
          Linkedln: "https://www.linkedin.com/company/nxp-semiconductors/",
          Twitter: "",
        },
      },
      {
        id: "64",
        name: "T&W",
        contact: {
          telephone: "",
          fax: "",
          name: "Lei Zhen",
          Email: "leizhen@twsz.com",
          Web: "",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "65",
        name: "Qualcomm",
        contact: {
          telephone: "",
          fax: "",
          name: "Jack Nasielski",
          Email: "jackn@qti.qualcomm.com",
          Web: "",
          Address: "",
          Linkedln: "",
          Twitter: "",
        },
      },
      {
        id: "66",
        name: "JMA Wireless",
        contact: {
          Web: "https://jmawireless.com/",
        },
      },
      {
        id: "67",
        name: "Cisco",
        contact: {
          Web: "http://www.cisco.com",
        },
      },
      {
        id: "68",
        name: "MiTAC Computing Technology Corp.",
        contact: {
          Web: "https://www.mitacmct.com",
          Email: "Sales_enterprise@mic.com.tw",
        },
      },
      {
        id: "70",
        name: "ASOCS",
        contact: {},
      },
      {
        id: "71",
        name: "University of Utah",
        contact: {
          Web: "https://www.powderwireless.net",
        },
      },
      {
        id: "72",
        name: "Dell",
        contact: {
          Web:
            "https://www.delltechnologies.com/en-us/industry/telecom/index.htm",
        },
      },
      {
        id: "73",
        name: "Northeastern University",
        contact: {
          Web: "https://www.northeastern.edu/wiot/",
        },
      },
      {
        id: "74",
        name: "Cohere Technologies",
        contact: {
          Web: "https://www.cohere-technologies.com/",
        },
      },
      {
        id: "75",
        name: "Auray Technology Corp",
        contact: {
          Web: "https://www.auray.com.tw",
        },
      },
      {
        id: "76",
        name: "Inspur",
        contact: {},
      },
      {
        id: "77",
        name: "EURECOM",
        contact: {},
      },
      {
        id: "78",
        name: "LG UPlus",
        contact: {},
      },
      {
        id: "79",
        name: "DZS",
        contact: {},
      },
      {
        id: "80",
        name: "Acceleran",
        contact: {},
      },
      {
        id: "81",
        name: "ADVA",
        contact: {},
      },
      {
        id: "82",
        name: "AirHop",
        contact: {
          Web: "https://www.airhopcomm.com/",
        },
      },
      {
        id: "83",
        name: "Anritsu",
        contact: {},
      },
      {
        id: "84",
        name: "EANTC",
        contact: {},
      },
      {
        id: "85",
        name: "EXFO",
        contact: {},
      },
      {
        id: "86",
        name: "Facebook",
        contact: {},
      },
      {
        id: "87",
        name: "Foxconn",
        contact: {},
      },
      {
        id: "88",
        name: "IP+Infusion",
        contact: {},
      },
      {
        id: "89",
        name: "Micas",
        contact: {},
      },
      {
        id: "90",
        name: "MTI",
        contact: {},
      },
      {
        id: "91",
        name: "ONF",
        contact: {},
      },
      {
        id: "92",
        name: "Precision+OT",
        contact: {},
      },
      {
        id: "93",
        name: "Wiwynn",
        contact: {
          Web: "https://www.wiwynn.com/",
        },
      },
      {
        id: "94",
        name: "Wistron NeWeb Corp.",
        contact: {
          Web: "https://www.wnc.com.tw/",
        },
      },
      {
        id: "95",
        name: "Casa Systems",
        contact: {
          Web: "https://www.casa-systems.com",
        },
      },
      {
        id: "96",
        name: "Rakuten Symphony",
        contact: {
          Web: "https://symphony.rakuten.com/symware",
        },
      },
      {
        id: "97",
        name: "LIONS Technology Inc.",
        contact: {
          Web: "https://www.lions.technology",
          telephone: "+886-3-620-1688",
          Address:
            "3F-2, No. 120, Sec. 2, Gongdao 5th Rd., Hsinchu City, 300046, Taiwan",
          Linkedln:
            "https://www.linkedin.com/company/lions5gran/?viewAsMember=true",
        },
      },
      {
        id: "98",
        name: "Red Hat",
        contact: {
          Web: "https://www.redhat.com/",
        },
      },
      {
        id: "99",
        name: "iConNext",
        contact: {
          Web: "https://www.icon-next.com/",
          Linkedln: "https://www.linkedin.com/company/iconnext",
          Twitter: "https://twitter.com/iConnextRAN",
          Facebook: "https://www.facebook.com/iConnextRAN",
          Instagram: "https://www.instagram.com/iconnext_co/",
          Youtube: "https://www.youtube.com/channel/UCIKggZ_16nmvDJy5KvxcpeA",
        },
      },
      {
        id: "100",
        name: "T-Mobile",
        contact: {},
      },
      {
        id: "101",
        name: "Orange",
        contact: {},
      },
      {
        id: "102",
        name: "Telefonica",
        contact: {},
      },
      {
        id: "103",
        name: "TIM",
        contact: {},
      },
      {
        id: "104",
        name: "Vodafone",
        contact: {},
      },
      {
        id: "105",
        name: "Pagetron",
        contact: {},
      },
      {
        id: "106",
        name: "IP Infusion",
        contact: {
          Web: "https://www.ipinfusion.com/",
        },
      },
      {
        id: "107",
        name: "Groundhog",
        contact: {
          telephone: "+886 2 83691018",
          Email: "contacttw@ghtinc.com",
          Web: "https://www.ghtinc.com",
          Address: "2F., No. 42, Sec. 2, Zhongshan N. Rd., Taipei 104, Taiwan",
          Linkedln:
            "https://www.linkedin.com/company/groundhog-technologies-inc./",
        },
      },
      {
        id: "108",
        name: "AMD",
        contact: {
          Web: "https://www.amd.com",
        },
      },
      {
        id: "109",
        name: "Accelercomm",
        contact: {
          Web: "https://www.accelercomm.com",
        },
      },
      {
        id: "110",
        name: "Sercomm",
        contact: {},
      },
      {
        id: "111",
        name: "Tata Consultancy Services",
        contact: {
          Web:
            "https://www.tcs.com/tcs-positioned-as-leader-in-5g-engineering-services-by-everest-group",
        },
      },
      {
        id: "112",
        name: "AMI",
        contact: {},
      },
      {
        id: "113",
        name: "VVDN Technologies",
        contact: {},
      },
      {
        id: "114",
        name: "Airtel",
        contact: {},
      },
      {
        id: "115",
        name: "Corning",
        contact: {},
      },
      {
        id: "116",
        name: "NI",
        contact: {},
      },
      {
        id: "117",
        name: "VVDN",
        contact: {},
      },
      {
        id: "118",
        name: "WNDRVR",
        contact: {},
      },
      {
        id: "119",
        name: "Aethertek",
        contact: {},
      },
      {
        id: "120",
        name: "Alpha Networks",
        contact: {},
      },
      {
        id: "121",
        name: "Arcadyan",
        contact: {},
      },
      {
        id: "122",
        name: "Compall",
        contact: {},
      },
      {
        id: "123",
        name: "Delta",
        contact: {},
      },
      {
        id: "124",
        name: "III",
        contact: {},
      },
      {
        id: "125",
        name: "Inventec",
        contact: {
          Web: "https://EBG.inventec.com",
        },
      },
      {
        id: "126",
        name: "JPC Connectivity",
        contact: {},
      },
      {
        id: "127",
        name: "Liteon",
        contact: {},
      },
      {
        id: "128",
        name: "Metanoia",
        contact: {},
      },
      {
        id: "129",
        name: "NKG",
        contact: {},
      },
      {
        id: "130",
        name: "OPrueba",
        contact: {},
      },
      {
        id: "131",
        name: "WNC",
        contact: {},
      },
      {
        id: "132",
        name: "KDDI",
        contact: {},
      },
      {
        id: "133",
        name: "Soft Bank",
        contact: {},
      },
      {
        id: "134",
        name: "Skoltech",
        contact: {},
      },
      {
        id: "135",
        name: "STL",
        contact: {},
      },
      {
        id: "136",
        name: "Actiontech",
        contact: {},
      },
      {
        id: "137",
        name: "FibroLAN",
        contact: {},
      },
      {
        id: "138",
        name: "Infinera",
        contact: {},
      },
      {
        id: "139",
        name: "Winlab",
        contact: {},
      },
      {
        id: "140",
        name: "Jabil",
        contact: {},
      },
      {
        id: "141",
        name: "Chunghwa Telecom",
        contact: {},
      },
      {
        id: "142",
        name: "CHT",
        contact: {},
      },
      {
        id: "143",
        name: "Free 5 GL",
        contact: {},
      },
      {
        id: "144",
        name: "Druid",
        contact: {},
      },
      {
        id: "145",
        name: "Microchip",
        contact: {},
      },
      {
        id: "146",
        name: "Spirent Communications",
        contact: {},
      },
      {
        id: "147",
        name: "Polte",
        contact: {
          Web: "http://www.polte.com/",
        },
      },
      {
        id: "148",
        name: "Cellwize",
        contact: {
          Web: "https://www.cellwize.com/",
        },
      },
      {
        id: "149",
        name: "Rimedo Labs",
        contact: {
          Web: "https://rimedolabs.com",
          Linkedln: "https://www.linkedin.com/company/rimedolabs/",
        },
      },
      {
        id: "150",
        name: "Marvell",
        contact: {
          Web: "https://www.marvell.com/",
        },
      },
      {
        id: "151",
        name: "GUANGDONG COMMUNICATIONS & NETWORKS INSTITUTE",
        contact: {
          Web: "http://www.gdcni.cn/",
          Address:
            "Building A, Runhui Science and Technology Park, No. 18 Shenzhou Road, Huangpu District, Guangzhou",
        },
      },
      {
        id: "152",
        name: "Amazon",
        contact: {
          Web: "",
        },
      },
      {
        id: "153",
        name: "Cloudify",
        contact: {
          Web: "",
        },
      },
      {
        id: "154",
        name: "New Hampshire",
        contact: {
          Web: "",
        },
      },
      {
        id: "156",
        name: "New Kinpo Group",
        contact: {
          Web: "",
        },
      },
      {
        id: "158",
        name: "Askey",
        contact: {
          Web: "",
        },
      },
      {
        id: "159",
        name: "Alpha Net",
        contact: {
          Web: "",
        },
      },
      {
        id: "160",
        name: "Ribbon",
        contact: {
          Web: "",
        },
      },
      {
        id: "161",
        name: "Meta",
        contact: {
          Web: "",
        },
      },
      {
        id: "162",
        name: "Interoperability Lab",
        contact: {
          Web: "",
        },
      },
      {
        id: "163",
        name: "Reign",
        contact: {
          Web: "https://www.htc.com/us/5g/reign-core/",
        },
      },
      {
        id: "164",
        name: "Nuray",
        contact: {
          Web: "",
        },
      },
      {
        id: "165",
        name: "Azcom Technology",
        contact: {
          Web: "https://www.azcomtech.com",
          Email: "sales@azcomtech.com",
        },
      },
      {
        id: "166",
        name: "Picocom",
        contact: {
          Web: "https://picocom.com/",
        },
      },
      {
        id: "167",
        name: "Infosys",
        contact: {
          Email: "Private.Networks@infosys.com",
          Web: "https://www.infosys.com",
          Address:
            "Infosys Limited, Hosur Road, Electronic City, Bangalore, Karnataka, India - 560100",
          Linkedln:
            "https://www.linkedin.com/showcase/infosys-engineering-services/",
        },
      },
      {
        id: "168",
        name: "NYCU",
        contact: {
          Web: "https://en.nycu.edu.tw/",
          Address:
            "No. 1001, Daxue Rd. East Dist., Hsinchu City 300093, Taiwan",
        },
      },
      {
        id: "169",
        name: "DeepSig",
        contact: {
          Web: "https://www.deepsig.ai/",
          Address: "1300 17th St N #1260, Arlington, VA 22209",
        },
      },
    ],
    contacts: [
      {
        contacts: [
          {
            Email: "kalyan.sundhar@keysight.com",
            Web: "http://www.keysight.com/find/ORAN",
          },
        ],
      },
      {
        contacts: [
          {
            Email: "kalyan.sundhar@keysight.com",
            Web: "http://www.keysight.com/find/ORAN",
          },
        ],
      },
      {
        contacts: [
          {
            Email: "Joakim.Granholm@keysight.com",
            Web: "http://www.keysight.com/find/ORAN",
          },
        ],
      },
      {
        contacts: [
          {
            Email: "Jean_Dassonville@keysight.com",
            Web: "http://www.keysight.com/find/ORAN",
          },
        ],
      },
      {
        contacts: [
          {
            Email: "avik.bhattacharya@keysight.com",
            Web:
              "https://www.keysight.com/us/en/products/network-test/protocol-load-test/ixnetwork.html",
          },
        ],
      },
      {
        contacts: [
          {
            Email: "avik.bhattacharya@keysight.com",
            Web:
              "https://www.keysight.com/us/en/products/network-test/protocol-load-test/ixnetwork.html",
          },
        ],
      },
      {
        contacts: [
          {
            Email: "avik.bhattacharya@keysight.com",
            Web:
              "https://www.keysight.com/us/en/products/network-test/protocol-load-test/ixnetwork.html",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "48",
            Email: "Mr. Linh Nguyen (linhnc1@viettel.com.vn)",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "4",
            name: "Masato Shindo",
            telephone: "+81-90-1501-8462",
            Email: "shindo@nec.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Jean Dassonville",
            Email: "jean_dassonville@keysight.com",
          },
          {
            brandId: "67",
            name: "Dinuraj K (dinuraj)",
            Email: "dinuraj@cisco.com",
            telephone: "+91 9845178881",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "28",
            name: "Rishi Nandwana",
            telephone: "+91-9716299129",
            Email: "Rishi.nandwana@stl.tech",
          },
          {
            brandId: "28",
            name: "Ashu Babbar",
            telephone: "+91-9716299073",
            Email: "Ashu.babbar@stl.tech",
          },
          {
            brandId: "28",
            name: "Ravi Prakash",
            telephone: "+91-7525023908",
            Email: "Ravi.prakash@stl.tech",
          },
          {
            brandId: "126",
            name: "Niv Zimmerman",
            telephone: "+1-7027676493",
            Email: "nivz@asocscloud.com",
          },
          {
            brandId: "126",
            name: "Gaby Guri",
            telephone: "+972-54-6626-585",
            Email: "gaby@asocscloud.com",
          },
          {
            brandId: "126",
            name: "Keren Lipshitz",
            Email: "kerenl@asocscloud.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "71",
            name: "David Johnson",
            Email: "johnsond@cs.utah.edu",
          },
          {
            brandId: "71",
            name: "Kobus Van der Merwe",
            Email: "kobus@cs.utah.edu",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "72",
            Email: "scott.heinlein@dell.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            Email: "engwei.koo@keysight.com",
          },
          {
            brandId: "31",
            Email: "jeffrey-cy_chen@keysight.com",
          },
          {
            brandId: "75",
            Email: "service@auray.com.tw",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "76",
            Email: "zhangyinxia@inspur.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "77",
            Email: "mikel.irazabal@eurecom.fr",
          },
          {
            brandId: "77",
            Email: "navid.nikaein@eurecom.fr",
          },
          {
            brandId: "77",
            Email: "robert.schmidt@eurecom.fr",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "94",
            Email: "NW.Sales@wnc.com.tw",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "95",
            name: "Kashif Hussain",
            telephone: "Office: +1 978 688.6706 ext: 6202",
            Email: "kashif.hussain@casa-systems.com",
          },
          {
            brandId: "58",
            name: "Melanie Mauersberger",
            telephone: "Office: +49 89 4129-16164",
            Email: "melanie.mauersberger@rohde-schwarz.com",
          },
          {
            brandId: "3",
            name: "Ken Trudgeon",
            telephone: " +1 2404043102",
            Email: "Ken.Trudgeon@viavisolutions.com",
            web: "https://www.viavisolutions.com/en-us/products/open-ran-test",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "96",
            name: "Jason King",
            Email: "jason.king@rakuten.com",
          },
          {
            brandId: "15",
            name: "Roxanne Gryder",
            Email: "roxanne.r.gryder@intel.com",
          },
          {
            brandId: "40",
            name: "Jai Thattil",
            Email: "jthattil@juniper.net",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "58",
            name: "Melanie Mauersberger",
            telephone: "Office: +49 89 4129-16164",
            Email: "melanie.mauersberger@rohde-schwarz.com",
          },
          {
            brandId: "57",
            name: "Peadar Forbes",
            Email: "Peadar.Forbes@analog.com",
          },
          {
            brandId: "3",
            name: "Ken Trudgeon",
            telephone: "+1 2404043102",
            Email: "Ken.Trudgeon@viavisolutions.com",
            web: "https://www.viavisolutions.com/en-us/products/open-ran-test",
          },
          {
            brandId: "15",
            name: "Ankur Vora",
            Email: "ankur.vora@intel.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "71",
            name: "David Johnson",
            Email: "johnsond@cs.utah.edu",
          },
          {
            brandId: "71",
            name: "Kobus Van der Merwe",
            Email: "kobus@cs.utah.edu",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "97",
            Email: "kan.chang@lions.technology",
          },
          {
            brandId: "97",
            Email: "jansen.chen@lions.technolgoy",
          },
          {
            brandId: "97",
            Email: "yee.chia@lions.technology",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "61",
            name: "Mo Jabbari",
            Email: "mo.jabbari@arm.com",
          },
          {
            brandId: "51",
            name: "Rajat Kapoor",
            Email: "rajat.kapoor@altran.com",
          },
          {
            brandId: "32",
            name: "Olli Andersson",
            Email: "olliandersson@benetel.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "8",
            name: "Bin Yang",
            telephone: "+86-13811391682",
            Email: "bin.yang@windriver.com",
          },
          {
            brandId: "8",
            name: "Gil Hellmann",
            Email: "gil.hellmann@windriver.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Razvan Arhip",
            Email: "razvan.arhip@keysight.com",
          },
          {
            brandId: "31",
            name: "Jean Manuel Dassonville",
            Email: "Jean_dassonville@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Jari Ryynanen",
            telephone: "+358 94 2573580",
            Email: "jari.ryynanen@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Rafa Garcia",
            telephone: "+34951510050",
            Email: "rafa_garcia@keysight.com",
          },
          {
            brandId: "31",
            name: "Jean Dassonville",
            telephone: "+17075772269",
            Email: "jean_dassonville@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Prashant Bankar",
            telephone: "+1 571-758-7204",
            Email: "prashant.bankar@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "99",
            Email: "Global_Sales@iConNext.com.tw",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "57",
            Email: "radioverse@analog.com",
            web: "https://Platform.radioverse.com",
          },
          {
            brandId: "31",
            name: "Razvan Arhip",
            Email: "razvan.arhip@keysight.com",
          },
          {
            brandId: "31",
            name: "Jean Manuel Dassonville",
            Email: "Jean_dassonville@keysight.com",
          },
          {
            brandId: "15",
            name: "Ankur Vora",
            Email: "ankur.vora@intel.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "100",
            name: "Nathan Rader",
            Email: "Nathan.Rader@telekom.de",
          },
          {
            brandId: "100",
            name: "Andreas Florath",
            Email: "Andreas.Florath@telekom.de",
          },
          {
            brandId: "101",
            name: "Guillaume Nevicato",
            Email: "guillaume.nevicato@orange.com",
          },
          {
            brandId: "101",
            name: "Christian Gallard",
            Email: "christian.gallard@orange.com",
          },
          {
            brandId: "102",
            name: "Luis Velarde",
            Email: "luis.velardetazon@telefonica.com",
          },
          {
            brandId: "103",
            name: "Giuseppe Ferrarisv",
            Email: "giuseppe.ferraris@telecomitalia.it",
          },
          {
            brandId: "103",
            name: "Carlo Cavazzoni",
            Email: "carlo.cavazzoni@telecomitalia.it",
          },
          {
            brandId: "104",
            name: "Volkmar Hammer",
            Email: "volkmar.hammer@vodafone.com",
          },
          {
            brandId: "104",
            name: "Tom Kivlin",
            Email: "tom.kivlin@vodafone.com",
          },
          {
            brandId: "34",
            name: "Norbert Niebert",
            Email: "norbert.niebert@ericsson.com",
          },
          {
            brandId: "27",
            name: "Tanveer Saad",
            Email: "tanveer.saad@nokia.com",
          },
          {
            brandId: "27",
            name: "Kalle Loukos",
            Email: "kalle.loukos@nokia.com",
          },
          {
            brandId: "27",
            name: "Marco Ciarla",
            Email: "marco.ciarla@nokia.com",
          },
          {
            brandId: "5",
            name: "Richard Bodin",
            Email: "richard.bodin@mavenir.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "75",
            Email: "service@auray.com.tw",
          },
          {
            brandId: "31",
            name: "Engwei Koo",
            Email: "engwei.koo@keysight.com",
          },
          {
            brandId: "31",
            name: "Jeffrey Chen",
            Email: "jeffrey-cy_chen@keysight.co",
            web:
              "https://www.keysight.com/us/en/cmp/2020/5g-o-ran-software.html",
          },
          {
            brandId: "105",
            name: "Pegatron 5G Team",
            Email: "pegatron5g@pegatroncorp.com",
            web: "https://www.pegatroncorp.com/index/index/lang/en_US",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "75",
            Email: "service@auray.com.tw",
          },
          {
            brandId: "31",
            name: "Jeffrey Chen",
            Email: "jeffrey-cy_chen@keysight.co",
          },
          {
            brandId: "31",
            Email: "engwei.koo@keysight.com",
            web:
              "https://www.keysight.com/us/en/cmp/2020/5g-o-ran-software.html",
          },
          {
            brandId: "106",
            name: "Rishi Narain",
            Email: "rishi.narain@ipinfusion.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "5",
            name: "Rajarajan",
            LinkedIn:
              "https://www.linkedin.com/in/dr-rajarajan-sivaraj-69698139/",
            Email: "Sivaraj@mavenir.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "34",
            name: "Haseeb Akhtar",
            Email: "haseeb.akhtar@ericsson.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "52",
            name: "Rafal Sanecki",
            telephone: "+48 533 422 622",
            LinkedIn: "https://www.linkedin.com/company/265128/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "47",
            name: "Chai-Hien Gan",
            Email: "chgan@itri.org.tw",
          },
          {
            brandId: "53",
            name: "Jason Peng",
            Email: "jason6_peng@pegatroncorp.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "47",
            name: "Wan-Yi Lin",
            Email: "wylin@itri.org.tw",
          },
          {
            brandId: "47",
            name: "Yao-Jen Tang",
            Email: "yjtang@itri.org.tw",
          },
          {
            brandId: "47",
            name: "Tai-Ming Wen",
            Email: "itriA50556@itri.org.tw",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "3",
            web:
              "https://www.viavisolutions.com/en-uk/products/tm500-o-du-tester",
            Email: "bethesda.shu@viavisolutions.com",
          },
          {
            brandId: "15",
            Email: "sindhu.xirasagar@intel.comm",
          },
          {
            brandId: "51",
            Email: "rajat.kapoor@capgemini.com",
          },
          {
            brandId: "106",
            Email: "john.dinh@ipinfusion.com",
          },
          {
            brandId: "106",
            Email: "rishi.narain@ipinfusion.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "3",
            web: "https://www.amd.com/en/solutions/networking-andtelco",
            Email: "Piotr.Weglicki@amd.com",
          },
          {
            brandId: "108",
            Email: "bethesda.shu@viavisolutions.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "40",
            name: "Arda Akman",
            Email: "aakman@juniper.net",
          },
          {
            brandId: "31",
            name: "Michael Jones",
            Email: "michael.jones@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "40",
            name: "Arda Akman",
            Email: "aakman@juniper.net",
          },
          {
            brandId: "82",
            name: "Joe Thome",
            Email: "jthome@airhopcomm.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "109",
            Email: "eric.dowek@accelercomm.com",
          },
          {
            brandId: "109",
            Email: "stuart.ching@accelercomm.com",
          },
          {
            brandId: "109",
            Email: "robert.barnes@accelercomm.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "28",
            name: "Rishi Nandwana",
            telephone: "+91-9716299129",
            Email: "Rishi.nandwana@stl.tech",
          },
          {
            brandId: "28",
            name: "Ashu Babbar",
            telephone: "+91-9716299073",
            Email: "Ashu.babbar@stl.tech",
          },
          {
            brandId: "28",
            name: "Ravi Prakash",
            telephone: "+91-7525023908",
            Email: "Ravi.prakash@stl.tech",
          },
          {
            brandId: "70",
            name: "Gaby Guri",
            telephone: "+972-54-6626-585",
            Email: "gaby@asocscloud.com",
          },
          {
            brandId: "70",
            name: "Nicc Lewis",
            telephone: "+972-54-8181724",
            Email: "niccl@asocscloud.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "110",
            Email: "carl_zhu@sdc.sercomm.com",
          },
          {
            brandId: "3",
            Email: "owen.odonnell@viavisolutions.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Yash Tanna",
            web:
              "https://www.keysight.com/us/en/product/NTL60018A/performance-benchmarking-solutionfor-lab-networks.html",
            Email: "yash.tanna@keysight.com",
          },
          {
            brandId: "6",
            name: "Ganesh Shenbagaraman",
            Email: "Ganesh.Shenbagaraman@radisys.com",
          },
          {
            brandId: "15",
            name: "Sindhu Xirasagar",
            Email: "Sindhu.Xirasagar@intel.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "13",
            name: "Wayne Zhang",
            Email: "zhanghao-yj@baicells.com",
          },
          {
            brandId: "13",
            name: "Nicole Chen",
            Email: "chenqiuyue@baicells.com",
          },
          {
            brandId: "15",
            name: "Zhu Yuan",
            Email: "yuan.zhu@intel.com",
          },
          {
            brandId: "8",
            name: "Bin Yang",
            telephone: "+86-13811391682",
            Email: "bin.yang@windriver.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "111",
            Email: "Radhakant.Das@tcs.com",
          },
          {
            brandId: "3",
            name: "Amit Malhotra",
            web:
              "https://www.viavisolutions.com/en-us/products/teravm-ric-test",
            Email: "amit.malhotra@viavisolutions.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "15",
            Email: "Jianli.sun@intel.com",
          },
          {
            brandId: "1",
            Email: "kj2681@att.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "25",
            Email: "5g_open_ran_ecosystem-ml@nttdocomo.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "23",
            name: "Yusuke Kanamori",
            web:
              "https://telco.vmware.com/products/telco-cloud-platform-ran.html",
            Email: "yusukek@vmware.com",
          },
          {
            brandId: "49",
            name: "Jason King",
            web: "http://www.altiostar.com",
            Email: "jking@altiostar.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "146",
            Email: "thomas.fleming@spirent.com",
            name: "Americas: Thomas Fleming",
            web: "https://www.spirent.com/solutions/open-ran-testing",
          },
          {
            brandId: "146",
            Email: "michael.blinch@spirent.com",
            name: "EMEA: Michael Blinch",
          },
          {
            brandId: "146",
            Email: "Jenny.Zheng@spirent.com",
            name: "China , Japan and Korea: Jenny Zhang",
          },
          {
            brandId: "146",
            Email: "Supreetha.Murthy@spirent.com",
            name: "SEA ANZ India: Supreetha Murthy",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "146",
            Email: "thomas.fleming@spirent.com",
            name: "Americas: Thomas Fleming",
            web: "https://www.spirent.com/solutions/open-ran-testing",
          },
          {
            brandId: "146",
            Email: "michael.blinch@spirent.com",
            name: "EMEA: Michael Blinch",
          },
          {
            brandId: "146",
            Email: "Jenny.Zheng@spirent.com",
            name: "China , Japan and Korea: Jenny Zhang",
          },
          {
            brandId: "146",
            Email: "Supreetha.Murthy@spirent.com",
            name: "SEA ANZ India: Supreetha Murthy",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "146",
            Email: "bi.li@spirent.com",
            web:
              "https://www.spirent.com/products/testcenter-platforms-protocols",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "74",
            name: "Camile Branin",
            Email: "camile.branin@cohere-technologies.com",
            web: "https://www.cohere-tech.com",
          },
          {
            brandId: "23",
            name: "Yusuke Kanamori",
            Email: "yusukek@vmware.com",
            web: "http://telco.vmware.com/products/ric.html",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "23",
            name: "Yusuke Kanamori",
            Email: "yusukek@vmware.com",
            web: "http://telco.vmware.com/products/ric.html",
          },
          {
            brandId: "147",
            name: "Christina Wicker",
            Email: "christina@polte.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "23",
            name: "Yusuke Kanamori",
            Email: "yusukek@vmware.com",
            web: "http://telco.vmware.com/products/ric.html",
          },
          {
            brandId: "82",
            name: "Joe Thome",
            Email: "jthome@airhopcomm.com",
            web: "http://www.airhopcomm.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "23",
            name: "Yusuke Kanamori",
            Email: "yusukek@vmware.com",
            web: "http://telco.vmware.com/products/ric.html",
          },
          {
            brandId: "148",
            name: "Hadas Shefler",
            Email: "hadas.shefler@cellwize.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "23",
            name: "Rafal Sanecki",
            Email: "info@is-wireless.com",
            telephone: "+48 533 422 622",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "149",
            name: "Marcin Dryjanski",
            Email: "marcin.dryjanski@rimedolabs.com",
          },
          {
            brandId: "91",
            name: "Denise Barton",
            Email: "denise@opennetworking.org",
            Linkedln: "https://www.linkedin.com/in/denise-barton-349b3/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "127",
            name: "Zora Wang",
            Email: "zora.wang@liteon.com",
          },
          {
            brandId: "127",
            name: "Maggie Ho",
            Email: "maggie.ho@liteon.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "42",
            Email: "ivan.chang@calnexsol.com",
            web: "https://www.calnexsol.com/en/",
          },
          {
            brandId: "42",
            Email: "info@calnexsol.com",
            web: "https://www.calnexsol.com/en/",
          },
          {
            brandId: "75",
            Email: "service@auray.com.tw",
            web: "https://www.auray.com.tw/",
          },
          {
            brandId: "53",
            Email: "pegatron5g@pegatroncorp.com",
            web: "https://www.pegatroncorp.com/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "42",
            web: "https://www.comba-telecom.com/en/openran-solutions",
            linkedIn: "https://www.linkedin.com/company/comba-telecom/",
            youTube: "https://www.youtube.com/channel/UCqOIMCMBwU06NWlY33qDr7Q",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "16",
            Email: "info@arraycomm.com",
            web: "https://www.marvell.com/",
          },
          {
            brandId: "121",
            Email: "brady_tseng@arcadyan.com",
            web: "http://www.arcadyan.com/",
          },
          {
            brandId: "63",
            web: "https://www.NXP.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "10",
            Email: "xuexu@chinamobile.com",
          },
          {
            brandId: "11",
            Email: "sunsc2@lenovo.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "42",
            Email: "albert.yuen@calnexsol.com",
            web: "https://www.calnexsol.com/en/",
          },
          {
            brandId: "75",
            Email: "service@auray.com.tw",
            web: "https://www.auray.com.tw/",
          },
          {
            brandId: "87",
            Email: "david.r.hoelscher@fii-foxconn.com",
            name: "David R. Hoelscher",
            web: "https://www.fii-foxconn.com/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "15",
            Email: "sindhu.xirasagar@intel.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "89",
            Email: "micas@micas-rf.com",
            web: "https://micas-rf.com/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "75",
            name: "Chunchi Su",
            Email: "chunchi.su@auray.com.tw",
          },
          {
            brandId: "87",
            name: "David R. Hoelscher",
            Email: "david.r.hoelscher@fii-foxconn.com",
          },
          {
            brandId: "58",
            name: "Matthias Weilhammer",
            Email: "mailto:Matthias.Weilhammer@rohde-schwarz.com",
          },
          {
            brandId: "3",
            name: "Ken Trudgeon",
            Email: "Ken.Trudgeon@viavisolutions.com",
            web: "http://viavisolutions.com/o-ran",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "16",
            Email: "linfo@arraycomm.com",
          },
          {
            brandId: "150",
            web: "https://www.marvell.com/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "151",
            Email: "yanjiuyuan@gdcni.cn",
            telephone: "0086-020-83289062",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "10",
            Email: "niweichen@chinamoibile.com",
          },
          {
            brandId: "11",
            Email: "yaosong1@lenovo.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "15",
            name: "Torre, Petar",
            Email: "petar.torre@intel.com",
          },
          {
            brandId: "51",
            name: "Yadav, Ashish",
            Email: "ashish.a.yadav@capgemini.com",
          },
          {
            brandId: "152",
            Email: "aymsaidi@amazon.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "93",
            Email: "sales@wiwynn.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "125",
            name: "Jason Chiang",
            Email: "Chiang.Jason@inventec.com",
            telephone: "+886-3-3900000 ext. 23464",
            Address:
              "No.88, Dazhi Rd., Taoyuan Dist., Taoyuan City 33068, Taiwan",
          },
          {
            brandId: "168",
            name: "En-Cheng Liou",
            Email: "ecl@nycu.edu.tw",
            Address:
              "No. 1001, Daxue Rd. East Dist., Hsinchu City 300093, Taiwan",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "23",
            name: "Diego Lozano de Fournas",
            Email: "dlozanodefou@vmware.com",
          },
          {
            brandId: "15",
            name: "Xirasagar, Sindh",
            Email: "sindhu.xirasagar@intel.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "8",
            name: "Jon Zhang",
            Email: "rong.zhang@windriver.com",
            telephone: "+86-1084777132",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "107",
            Email: "contacttw@ghtinc.com",
            telephone: "+886 2 83691018",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "97",
            name: "Kan Chang",
            Email: "kan.chang@lions.technology",
          },
          {
            brandId: "97",
            name: "Jansen Cheng",
            Email: "jansen.cheng@lions.technology",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "165",
            name: "Davide Denti",
            Email: "davide.denti@azcomtech.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "48",
            name: "linhnc1@viettel.com.vn",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "48",
            name: "LINH, NGUYEN CHI",
            Email: "linhnc1@viettel.com.vn",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "48",
            name: "Mr. Linh",
            Email: "linhnc1@viettel.com.vn",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "16",
            telephone: "+86-028-8453 6637",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "16",
            telephone: "+86-028-8453 6637",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "166",
            name: "Vicky Messer - VP Product Management",
            Email: "vicky.messer@picocom.com",
            Linkedln: "https://www.linkedin.com/in/vickymesser/",
          },
          {
            brandId: "166",
            name: "Steve Sprange - Technical Marketing Manager",
            Email: "steve.sprange@picocom.com",
            Linkedln: "https://www.linkedin.com/in/steve-sprange-0aa59619/",
          },
          {
            brandId: "166",
            name: "Oliver Davies - VP Marketing",
            Email: "oliver.davies@picocom.com",
            Linkedln: "https://www.linkedin.com/in/oliversdavies/",
          },
          {
            brandId: "6",
            name:
              "Mangal Singh - Head of Product Management MobilityEngine - Wireless",
            Email: "Mangal.Singh@Radisys.com",
          },
          {
            brandId: "6",
            name: "Gaurav Sharma - Head of Business development MobilityEngine",
            Email: "Gaurav.Sharma@radisys.com",
          },
          {
            brandId: "6",
            name: "Richard Russell - Head of Global MobilityEngine Sales",
            Email: "Richard.Russell@Radisys.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "52",
            name: "Rafal Sanecki",
            telephone: "+48 533 422 622",
            Linkedln: "https://www.linkedin.com/company/265128/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Razvan Arhip",
            Email: "azvan.arhip@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "168",
            name: "En-Cheng Liou",
            Email: "ecl@nycu.edu.tw",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "163",
            name: "Daniel Han",
            Email: "daniel_han@reignnet.com",
            web: "https://www.reignnet.com/",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "71",
            name: "David Johnson",
            Email: "johnsond@flux.utah.edu",
          },
          {
            brandId: "71",
            name: "Kobus van Der Merwe",
            Email: "kobus@cs.utah.edu",
          },
          {
            brandId: "31",
            name: "Michael Jones",
            Email: "michael.jones@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "11",
            Email: "gpupio@lenovo.com",
          },
          {
            brandId: "11",
            Email: "sunsc2@Lenovo.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "34",
            name: "Haseeb Akhtar",
            Email: "haseeb.akhtar@ericsson.com",
            Web:
              "https://www.ericsson.com/en/ran/intelligent-ran-automation/intelligent-automation-platform",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "3",
            Email: "bethesda.shu@viavisolutions.com",
            telephone: "+44(0)7912577045",
            Web:
              "https://www.viavisolutions.com/enuk/products/tm500-o-ru-tester#overviewhttp://viavisolutions.com/o-ran",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "3",
            Email: "James.Trinh@viavisolutions.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            name: "Cliff Tavares",
            Email: "Clifford.tavares@keysight.com",
          },
          {
            brandId: "31",
            name: "Lloyd Serra",
            Email: "Lloyd.serra@keysight.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "83",
            Email: "Tomohiro.Ito@anritsu.com",
            web:
              "https://www.anritsu.com/en-us/testmeasurement/products/mt8000a",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "17",
            Email: "Tony.Tam@cigtech.com",
          },
          {
            brandId: "136",
            Email: "Mark.Choi@actiontec.com",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "151",
            Email: "yanjiuyuan@gdcni.cn",
            telephone: "0086-020-83289062",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "31",
            Email: "avik.bhattacharya@keysight.com",
            name: "Avik Bhattacharya",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "169",
            Email: "doberholzer@deepsig.ai",
            name: "David Oberholzer",
          },
          {
            brandId: "169",
            Email: "tim@deepsig.ai",
            name: "Tim O’Shea",
          },
          {
            brandId: "169",
            Email: "jimshea@deepsig.ai",
            name: "Jim Shea",
          },
        ],
      },
      {
        contacts: [
          {
            brandId: "108",
            name: "Anthony Collins",
            Email: "Anthony.collins@amd.com",
            telephone: "+49 89 4129-16164",
          },
          {
            brandId: "58",
            Email: "melanie.mauersberger@rohde-schwarz.com",
            name: "Melanie Mauersberger",
            telephone: "Office: +49 89 4129-16164",
          },
        ],
      },
    ],
  }),
  async mounted() {
    // console.warn(this.$route);

    console.warn({
      id: this.$route.query.id,
      route: this.$route,
    });

    if (this.$route.query.id != null)
      this.$store.commit("eventIdSave", this.$route.query.id);

    //if (this.$route.query.page == "list") {
    //  this.$router.push("/list");
    //} else {
    await this.tryLogin();
    //}
  },
  methods: {
    async onSubmit() {
      this.$refs.form.validate();

      if (this.valid) {
        this.loading = true;
        let result;

        if (this.hasAccount) {
          result = await this.saveAccount();
          this.alertMessage = "Thank you for your update";
        } else {
          result = await this.addAccount();
          this.alertMessage = "Thank you for your registration";
        }

        setTimeout(() => {
          this.loading = false;
          /*
          console.warn({
            submitResult: result,
          });
         */

          if (result.data.success) {
            this.alertShow("success");
            this.hasAccount = true;
            localStorage["data"] = result.data.data.email;
          } else {
            localStorage["data"] = null;
            this.alertShow("error");
            this.alertMessage = "Server error, contant administrator please.";
          }
        }, 1000);
      }
    },
    async onSubmitAccount(event) {
      // console.warn("onSubmitAccount");

      this.$refs.formAccount.validate();

      if (event) event.preventDefault();

      if (this.validAccount) {
        this.loading = true;
        const result = await this.getAccount();

        setTimeout(() => {
          this.loading = false;

          /*
          console.warn({
            submitAccountResult: result,
          });
          */

          if (result.data.success) {
            this.meeting = result.data.meeting;
          }

          if (!result.data.success) {
            this.hasNotAccount = true;
            this.$store.commit("userAuthSave", null);
            this.$store.commit("userCreateEmail", this.email);
            this.$router.push(
              "/account?id=" + this.$store.getters.getEventId + "&action=create"
            );
          } else if (result.data.success && result.data.token == null) {
            this.tokenRequest = true;
          } else if (result.data.success && result.data.token == false) {
            this.tokenRequest = true;
          } else if (result.data.success && result.data.token == true) {
            this.tokenRequest = null;

            this.hasAccount = true;

            this.name = result.data.data.name;
            this.email = result.data.data.email;
            this.phone = result.data.data.phone;
            this.company = result.data.data.company;
            this.status = result.data.data.status;

            localStorage["data"] = result.data.data.email;

            this.$store.commit("userAuthSave", result.data.data);
            this.$store.commit("meetingSave", result.data.meeting);

            this.$router.push("/account?id=" + this.$store.getters.getEventId);
          }
        }, 1000);
      }
    },
    async addAccount() {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/Add",
        {
          Name: this.name,
          Email: this.email,
          Phone: this.phone,
          Company: this.company,
          Status: this.status,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async saveAccount() {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/Update",
        {
          Name: this.name,
          Email: this.email,
          Phone: this.phone,
          Company: this.company,
          Status: this.status,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async getAccount() {
      // console.warn("getAccount");

      console.warn({
        store: this.$store.getters.getEventId,
      });

      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/Account",
        {
          email: this.email,
          eventId: this.$store.getters.getEventId,
          token: this.token,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async getMeeting() {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/Meeting",
        {
          eventId: this.$store.getters.getEventId,
        }
      );
    },
    async tryLogin() {
      const store = localStorage["data"];

      var meeting = await this.getMeeting();
      this.meeting = meeting.data.meeting;
      this.$store.commit("meetingSave", meeting.data.meeting);

      if (store) {
        let result = this.validEmail(store);
        this.email = store;

        if (result) {
          await this.onSubmitAccount();
        }
      }
    },
    validEmail: (email) => {
      var re = /^[a-zA-Z0-9._-]+@(?!gmail\.com)(?!yahoo\.com)(?!Hotmail\.com)(?!Outlook\.com)(?!Aol\.com)(?!Mail\.com)(?!Yandex\.ru)(?!Icloud\.com)(?!Msn\.com)(?!Live\.com)(?!Rediffmail\.com)(?!163\.com)(?!QQ\.com)(?!Gmx\.com)(?!Zoho\.com)(?!Mail\.ru)(?!Protonmail\.com)(?!Outlook\.com\.br)(?!Naver\.com)(?!Daum\.net)(?!seznam\.cz)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      return re.test(email);
    },
    onLogout() {
      delete localStorage["data"];
      this.hasAccount = false;
      this.hasNotAccount = false;
    },
    getStatuses() {
      return this.hasAccount
        ? this.statuses
        : this.statuses.filter((x) => x.text != "Canceled");
    },
    alertShow(alertType) {
      this.showAlert = true;
      this.alertType = alertType;

      setTimeout(() => {
        this.showAlert = false;
        this.alertType = null;
        this.alertMessage = "";
      }, 2000);
    },
    onAgenda() {
      this.$router.push("/agenda");
    },
    getItems() {
      var items = [];

      this.contacts.map((x) => {
        // console.warn(x);

        for (let index = 0; index < x.contacts.length; index++) {
          const element = x.contacts[index];

          items.push(element);
        }
      });

      // console.warn(items);

      return items.map((x) => {
        var brand = this.brands.find((y) => y.id == x.brandId);

        return {
          Email: x.Email ? x.Email : x.email,
          Web: x.Web ? x.Web : x.web,
          Name: x.Name ? x.Name : x.name,
          Telephone: x.Telephone ? x.Telephone : x.telephone,
          Address: x.Address
            ? x.Address.replace(",", ";")
            : x.address
            ? x.address.replace(",", ";")
            : "",
          Linkedln: x.Linkedln ? x.Linkedln : x.linkedln,
          Brand: brand ? brand.name : "",
          BrandContactWeb: brand ? brand.contact.Web : "",
          BrandContactName: brand ? brand.contact.name : "",
          BrandContactPhone: brand ? brand.contact.telephone : "",
          BrandContactEmail: brand ? brand.contact.Email : "",
          BrandContactLinkedln: brand ? brand.contact.Linkedln : "",
          BrandContactAddress: brand
            ? brand.contact.Address
              ? brand.contact.Address.replace(",", ";")
              : ""
            : "",
        };
      });
    },
  },
};
