/* eslint-disable */
export default {
  development: {
    API: "http://localhost:5245/api/", // API: "https://localhost:7245/api/" http://localhost:5245/api/ https://f2fmeeting-dev.azurewebsites.net/api/ https://f2f-meetings-api.app-o-ran.org/api/
    Source: "http://localhost:8080/",
  },
  production: {
    API: "https://f2f-meeting-prod.azurewebsites.net/api/", //  https://f2fmeeting-dev.azurewebsites.net/api/  https://f2f-meeting-prod.azurewebsites.net/api/
    Source: "https://f2f-meetings.app-o-ran.org/", //  https://purple-hill-02598f303.1.azurestaticapps.net/  https://ambitious-island-0861f2d03.1.azurestaticapps.net/
  },
};
